import { configureStore } from '@reduxjs/toolkit'
import drawerReducer from './features/drawerSlice'
import editorReducer from './features/editorSlice'
import notificationReducer from './features/notificationSlice'
import menuReducer from './features/menuSlice'
import userReducer from './features/userSlice'

import { setupListeners } from '@reduxjs/toolkit/query/react'
import { stravaConnectApi, stravaDataApi } from './services/strava'

export const store = configureStore({
    reducer: {
        drawer: drawerReducer,
        editor: editorReducer,
        notification: notificationReducer,
        menu: menuReducer,
        user: userReducer,
        [stravaConnectApi.reducerPath]: stravaConnectApi.reducer,
        [stravaDataApi.reducerPath]: stravaDataApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(stravaConnectApi.middleware)
            .concat(stravaDataApi.middleware),
})

setupListeners(store.dispatch)

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setNotification } from 'State/features/notificationSlice'
import { setStravaData } from 'State/features/userSlice'
import { getSeconds } from 'Utils/getSeconds'

// Define a service using a base URL and expected endpoints
export const stravaConnectApi = createApi({
    reducerPath: 'stravaConnect',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.GATSBY_API_BASE_ENDPOINT}/api`,
    }),
    endpoints: (builder) => ({
        getStravaAuth: builder.query({
            query: (code) => ({
                url: '/strava-auth',
                method: 'POST',
                body: { code },
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                // `onStart` side-effect
                dispatch(setNotification('Connecting to Strava'))
                try {
                    const { data } = await queryFulfilled
                    if (
                        data.access_token &&
                        data.refresh_token &&
                        data.athlete.id &&
                        data.expires_at
                    ) {
                        dispatch(
                            setStravaData({
                                accessToken: data.access_token,
                                id: data.athlete.id,
                                refreshToken: data.refresh_token,
                                expires: data.expires_at,
                                profileImage: data?.athlete.profile,
                            })
                        )
                        dispatch(setNotification('Strava connected'))
                    }
                } catch (err) {
                    dispatch(setNotification('Unable to connect to Strava'))
                }
            },
        }),
    }),
})

export const { useGetStravaAuthQuery } = stravaConnectApi

const baseQuery = fetchBaseQuery({
    baseUrl: `https://www.strava.com/api/v3`,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getState().user?.thirdParty?.strava?.accessToken
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    // Get Strava state.
    const stravaState = api.getState().user.thirdParty.strava
    const stravaExpiry = stravaState.expires

    // If the auth token has not expired,
    // run the required query and move on.
    if (stravaExpiry > getSeconds()) {
        return await baseQuery(args, api, extraOptions)
    }

    const stravaRefreshToken = stravaState.refreshToken

    api.dispatch(setNotification('Checking Strava connection'))

    const stravaRes = await fetch(
        `${process.env.GATSBY_API_BASE_ENDPOINT}/api/strava-refresh`,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                token: stravaRefreshToken,
            }),
        }
    )

    const refreshResult = await stravaRes.json()

    if (refreshResult.data) {
        api.dispatch(
            setStravaData({
                ...stravaState,
                accessToken: refreshResult.data.access_token,
                refreshToken: refreshResult.data.refresh_token,
                expires: refreshResult.data.expires_at,
            })
        )
        api.dispatch(setNotification('Strava connected'))

        // retry the initial query
        return await baseQuery(args, api, extraOptions)
    }

    api.dispatch(setNotification('Unable to connect to Strava'))
    return { error: 'Strava refresh token expired' }
}

export const stravaDataApi = createApi({
    reducerPath: 'stravaData',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getStravaRoutes: builder.query({
            query: ({ userId, page = 1 }) => ({
                url: `/athletes/${userId}/routes?page=${page}`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetStravaRoutesQuery } = stravaDataApi

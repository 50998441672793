import { createSlice } from '@reduxjs/toolkit'

const initialState = [
    { active: true, name: 'Route', slug: 'route' },
    {
        name: 'Base',
        slug: 'base',
        children: [
            { name: 'Image', slug: 'image', active: true },
            { name: 'Colour', slug: 'colour' },
        ],
    },
    { name: 'Info', slug: 'info' },
    { name: 'Layout', slug: 'layout' },
]

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setSelectedPrimaryMenuItem: (state, action) => {
            const currentActive = state.find(({ active }) => active)
            currentActive.active = false
            const toSetActive = state.find(
                ({ slug }) => slug === action.payload
            )
            toSetActive.active = true
        },
        setSelectedSecondaryMenuItem: (state, action) => {
            const parent = state.find(
                ({ slug }) => slug === action.payload.parent
            )

            const children = parent.children

            const currentActive = children.find(({ active }) => active)
            currentActive.active = false

            const toSetActive = children.find(
                ({ slug }) => slug === action.payload.slug
            )

            toSetActive.active = true
        },
    },
})

export const { setSelectedPrimaryMenuItem, setSelectedSecondaryMenuItem } =
    menuSlice.actions

export default menuSlice.reducer

export const getMenu = (state) => state.menu

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    thirdParty: {
        mapmyrun: {
            id: null,
            accessToken: null,
            refreshToken: null,
            expires: null,
            profileImage: null,
        },
        strava: {
            id: null,
            accessToken: null,
            refreshToken: null,
            expires: null,
        },
    },
    routes: {
        mapmyrun: [],
        strava: [],
    },
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setStravaData: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const payload = {
                id: action.payload.id,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                expires: action.payload.expires,
                profileImage: action.payload.profileImage,
            }
            state.thirdParty.strava = payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setStravaData } = userSlice.actions

export default userSlice.reducer

export const getUserThirdPartyIds = (state) => {
    const thirdParty = state.user.thirdParty
    const mapmyrun = thirdParty.mapmyrun.id
    const strava = thirdParty.strava.id

    return { mapmyrun, strava }
}

export const getUserAuth = (state) => {
    const thirdParty = state.user.thirdParty
    const mapMyRun = Boolean(
        thirdParty.mapmyrun.id && thirdParty.mapmyrun.accessToken
    )
    const strava = Boolean(
        thirdParty.strava.id && thirdParty.strava.accessToken
    )

    return { mapMyRun, strava }
}

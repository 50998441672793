import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message: '',
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.message = action.payload
        },
    },
})

export const { setNotification } = notificationSlice.actions

export default notificationSlice.reducer

export const getCurrentNotificationMessage = (state) =>
    state.notification.message

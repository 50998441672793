import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    drawer: '',
}

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        openDrawer: (state, action) => {
            state.drawer = action.payload
        },
        closeDrawer: (state) => {
            state.drawer = ''
        },
    },
})

export const { openDrawer, closeDrawer } = drawerSlice.actions

export default drawerSlice.reducer

export const getCurrentDrawer = (state) => state.drawer.drawer

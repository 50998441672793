import { createSlice } from '@reduxjs/toolkit'
import { createHash } from 'Utils/createHash'

const cleanImageState = {
    x: 0,
    y: 0,
    width: null,
    height: null,
    rotation: 0,
    hash: null,
    image: null,
}

const initialState = {
    canvas: {
        width: null,
        color: {
            r: 115,
            g: 110,
            b: 254,
            a: 1,
        },
    },
    route: {
        color: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
        },
        elev: null,
        hash: null,
        polyline: '',
        scaleY: 1,
        scaleX: 1,
        rotation: 0,
        x: 40,
        y: 40,
    },
    info: {
        name: '',
        distance: '',
        elevation: '',
        unit: 'metric',
        color: {
            r: 105,
            g: 118,
            b: 137,
            a: 1,
        },
    },
    image: cleanImageState,
}

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        setCanvasColor: (state, action) => {
            state.canvas.color = action.payload
        },
        setCanvasWidth: (state, action) => {
            state.canvas.width = action.payload
        },
        setRouteColor: (state, action) => {
            state.route = {
                ...state.route,
                color: action.payload,
            }
        },
        setRoutePolyline: (state, action) => {
            state.route = {
                ...state.route,
                polyline: action.payload,
                hash: createHash(JSON.stringify(action.payload)),
            }
        },
        setImageFile: (state, action) => {
            state.image = {
                ...state.image,
                image: action.payload.data,
                width: action.payload.width,
                height: action.payload.height,
                hash: createHash(action.payload),
            }
        },
        removeImageFile: (state) => {
            state.image = cleanImageState
        },
        setImagePosition: (state, action) => {
            state.image = action.payload
        },
        setInfoData: (state, action) => {
            state.info = { ...state.info, ...action.payload }
        },
    },
})

export const {
    setCanvasColor,
    setCanvasWidth,
    setRouteColor,
    setRoutePolyline,
    setImageFile,
    removeImageFile,
    setImagePosition,
    setInfoData,
} = editorSlice.actions

export default editorSlice.reducer

export const getCanvasData = (state) => state.editor.canvas
export const getRouteData = (state) => state.editor.route
export const getInfoData = (state) => state.editor.info
export const getImageData = (state) => state.editor.image
